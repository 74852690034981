import parse from 'html-react-parser';
import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row, Table, Tooltip,OverlayTrigger, Pagination } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useUserContext } from '../services/UserProvider';
import { createCampaign, fetchCustomFields, assignDesign, assignMessage, deleteCampaign, fetchCampaigns, getAllRecipientCounts, getAssignedDesigns, getAssignedMessages, getUserDesigns, getUserMessages, postingCountry, updateCampaign } from '../services/api';
import { log } from '../services/logger';
import CreateCampaignModal from './CreateCampaignModal';
import CampaignStatusCell from './CampaignStatusCell';
import CampaignNextStep from './CampaignNextStep';
import CampaignWizard from './CampaignWizard';
import CreateQuickCampaign from './CreateQuickCampaign'; // Import the Quick Campaign modal
import Select from 'react-select';
import CopyCampaignModal from './CopyCampaignModal';


const useFetchData = (user, setUserId, setCampaigns, setAssignedMessages, setUserDesigns, setAssignedDesigns, setUserMessages, setRecipientCount, refreshData) => {
  useEffect(() => {
    if (user) {
      const decodedToken = jwtDecode(user);
      log(decodedToken);
      setUserId(decodedToken.userId);

      const fetchAllData = async () => {
        try {
          const [recipientCount, campaignsResponse, assignedMessagesResponse, userDesignsResponse, assignedDesignsResponse, userMessagesResponse] = await Promise.all([
            getAllRecipientCounts(),
            fetchCampaigns(decodedToken.userId),
            getAssignedMessages(decodedToken.userId),
            getUserDesigns(decodedToken.userId),
            getAssignedDesigns(decodedToken.userId),
            getUserMessages(decodedToken.userId),
          ]);
          setCampaigns(campaignsResponse.data);
          setAssignedMessages(assignedMessagesResponse.data);
          setUserDesigns(userDesignsResponse.data);
          setAssignedDesigns(assignedDesignsResponse.data);
          setUserMessages(userMessagesResponse.data);
          setRecipientCount(recipientCount.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchAllData();
    }
  }, [user, setUserId, setCampaigns, setAssignedMessages, setUserDesigns, setAssignedDesigns, setUserMessages, setRecipientCount, refreshData]);
};

const CampaignsPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [showWizard, setShowWizard] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const shouldOpenModal = queryParams.get('new') === 'true';
  const handleShowModal = () => setShowModal(true);
  const { user } = useUserContext(); // Use the user context
  const [campaigns, setCampaigns] = useState([]); // State to store the list of campaigns
  const [userId, setUserId] = useState(null);
  const [editingCampaign, setEditingCampaign] = useState(null); // Track the campaign being edited
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showDesignAssignModal, setShowAssignDesignModal] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [selectedDesign, setSelectedDesign] = useState(null);
  const [assignedMessages, setAssignedMessages] = useState([]);
  const [assignedDesigns, setAssignedDesigns] = useState([]);
  const [userMessages, setUserMessages] = useState([]);
  const [userDesigns, setUserDesigns] = useState([]);
  const [recipientCount, setRecipientCount] = useState([]);
  const [userCountry, setUserCountry] = useState([]);
  const [refreshData, setRefreshData] = useState(false); // A state to trigger refresh
  const [expandedParents, setExpandedParents] = useState([]); // Track which parent campaigns are expanded
  const [showQuickCampaignModal, setShowQuickCampaignModal] = useState(false); // State for Quick Campaign modal
  const [customFields, setCustomFields] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const itemsPerPage = 10; // Number of items per page
  const [searchQuery, setSearchQuery] = useState(''); // Search input
  const [sortField, setSortField] = useState('createdAt'); // The column to sort by
  const [sortOrder, setSortOrder] = useState('desc'); // 'asc' for ascending, 'desc' for descending
  const [showCopyModal, setShowCopyModal] = useState(false); // State for Copy Campaign modal
  const [campaignToCopy, setCampaignToCopy] = useState(null); // Campaign to be copied

  useEffect(() => {
    const fetchFields = async () => {
      try {
        const response = await fetchCustomFields(); // Call your API
        setCustomFields(response.data); // Update state with fetched fields
      } catch (error) {
        console.error('Error fetching custom fields:', error);
      }
    };
  
    fetchFields();
  }, []);

  const hasCustomFieldsForCreation = () => {
    return customFields.some(field => field.useForCreation);
  };
  
  const handleCopyModal = () => {
    setShowCopyModal(true);
    //setEditingCampaign(null); // Reset editing message
    //triggerCampaignReload();
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingCampaign(null); // Reset editing message
    triggerCampaignReload();
  };

  const handleCreateQuickCampaign = () => {
    setShowQuickCampaignModal(true); // Open the Quick Campaign modal
  };

  const handleCloseQuickCampaignModal = () => {
    setShowQuickCampaignModal(false); // Close the modal
    triggerCampaignReload(); // Refresh campaigns after creation
  };

    // Helper function to find custom fields by name
    const getCustomFieldValue = (customFields, fieldName) => {
      const field = customFields.find(f => f.name === fieldName);
      return field ? field.value : 'N/A';
    };

  
    const renderTooltip = (props, campaign) => (
      <Tooltip id="button-tooltip" {...props}>
        <strong>Created At:</strong> {campaign.createdAt}<br />
        {/* Loop through custom fields, excluding 'createdAt' */}
        {campaign.customFields
          .filter(field => field.label !== 'createdAt')
          .map((field) => (
            <div key={field && field._id ? field._id : Math.random()}>
              <strong>{field.label}:</strong> 
              {/* Explicitly handle boolean values */}
              {typeof field.value === 'boolean' ? (field.value ? 'Yes' : 'No') : field.value}<br />
            </div>
          ))}
      </Tooltip>
    );
    

  // Re-fetch campaigns whenever refreshData changes
  useFetchData(user, setUserId, setCampaigns, setAssignedMessages, setUserDesigns, setAssignedDesigns, setUserMessages, setRecipientCount, refreshData);

  useEffect(() => {
    const fetchAndSetUserCountry = async () => {
      try {
        const countryData = await postingCountry(); // Adjust as necessary for your API call
        setUserCountry(countryData.data.postingCountry); // Set the fetched data
        log(countryData.data.postingCountry);
      } catch (error) {
        console.error('Error fetching user country:', error);
      }
    };

    fetchAndSetUserCountry();
  }, []);

  useEffect(() => {
    if (shouldOpenModal) {
      setShowModal(true);
    }
  }, [shouldOpenModal]);

  // Call this function after actions like updating, deleting, etc.
  const triggerCampaignReload = () => {
    setRefreshData(!refreshData); // This will trigger useEffect to reload campaigns
  };

  // Group campaigns by parent-child relationship
  const groupCampaignsByParent = (campaigns) => {
    const parentChildMap = {};

    campaigns.forEach((campaign) => {
      if (campaign.parentCampaign) {
        if (!parentChildMap[campaign.parentCampaign]) {
          parentChildMap[campaign.parentCampaign] = { parent: null, children: [] };
        }
        parentChildMap[campaign.parentCampaign].children.push(campaign);
      } else {
        if (!parentChildMap[campaign._id]) {
          parentChildMap[campaign._id] = { parent: null, children: [] };
        }
        parentChildMap[campaign._id].parent = campaign;
      }
    });

    return Object.values(parentChildMap);
  };

  const toggleExpandParent = (parentId) => {
    if (expandedParents.includes(parentId)) {
      setExpandedParents(expandedParents.filter(id => id !== parentId)); // Collapse if expanded
    } else {
      setExpandedParents([...expandedParents, parentId]); // Expand if collapsed
    }
  };

  const handleEditCampaign = (campaign) => {
    setEditingCampaign(campaign); // Set the campaign to be edited
    handleShowModal(); // Open the modal
  };

  const handleEditCampaignWithWizard = (campaign) => {
    setEditingCampaign(campaign); // Set the campaign to be edited
    setShowWizard(true); // Open the CampaignWizard
  };

  const handleSubmitForReview = (campaign) => {
    if (!assignedMessages.find(m => m.campaignId === campaign._id)) {
      alert("please assign a message before submitting for review");
    } else if (!assignedDesigns.find(d => d.campaignId === campaign._id)) {
      alert("please assign a design before submitting for review");
    } else if (campaign.statusV1 === 'pending' || campaign.statusV1 === 'draft') {
      const inReviewCampaign = { ...campaign, 'statusV1': 'in-review' }
      updateCampaign(campaign._id, inReviewCampaign).then(() => {
        triggerCampaignReload(); // Reload campaigns after submission
      });
    }
  }

  const handleCancelReview = (campaign) => {
    if (campaign.statusV1 === 'in-review') {
      const inReviewCampaign = { ...campaign, 'statusV1': 'draft' }
      updateCampaign(campaign._id, inReviewCampaign).then(() => {
        triggerCampaignReload(); // Reload campaigns after cancellation
      });
    }
  }

  const handleCloseCampaign = (campaign) => {
    if (campaign.statusV1 === 'live') {
      if (window.confirm("Are you sure you want to close this campaign?  Once closed it will no longer be able to accept new recipients for posting")) {
        const closedCampaign = { ...campaign, 'statusV1': 'closed' }
        updateCampaign(campaign._id, closedCampaign).then(() => {
          triggerCampaignReload(); // Reload campaigns after closing
        });
      }
    }
  }

 // Sort messages alphabetically by messageName
const sortedMessages = userMessages.slice().sort((a, b) =>
  a.messageName.toLowerCase().localeCompare(b.messageName.toLowerCase())
);

const messageOptions = sortedMessages.map(msg => ({
  value: msg._id,
  label: msg.messageName
}));


  const handleDeleteCampaign = (campaignId) => {
    if (window.confirm("Are you sure you want to delete this campaign? Your recipients associated to your campaign will be deleted as well. It's not reversable.?")) {
      deleteCampaign(campaignId)
        .then(() => {
          log('Campaign deleted');
          triggerCampaignReload(); // Reload campaigns after deletion
        })
        .catch(error => {
          console.error('Error deleting campaign:', error);
        });
    }
  };

  const isEditableCampaign = (campaign) => {
    return campaign.statusV1 === 'pending' || campaign.statusV1 === 'draft';
  }

  const createMessageCell = (campaign, messageId) => {
    const message = userMessages.find(msg => msg._id === messageId);
    return message
      ? <>
        {message.messageName} {isEditableCampaign(campaign) && <Button variant="outline-primary" size="sm" onClick={() => handleOpenAssignMessage(campaign, messageId)}><i className="bi bi-pencil" /></Button>}
      </>
      : <Button variant="warning" size="sm" onClick={() => handleOpenAssignMessage(campaign)}>Assign Message</Button>;
  };

  const createDesignCell = (campaign, designId) => {
    const design = userDesigns.find(des => des._id === designId);
    return design
      ? <>
        {design.designName} {isEditableCampaign(campaign) && <Button variant="outline-primary" size="sm" onClick={() => handleOpenAssignDesign(campaign, designId)}><i className="bi bi-pencil" /></Button>}
      </>
      : <Button variant="warning" size="sm" onClick={() => handleOpenAssignDesign(campaign)}>Assign Design</Button>
  }

  const createRecipientCell = (campaignId) => {
    const campaignData = recipientCount.find(rc => rc.campaignId === campaignId);
    return <>
      {campaignData ? campaignData.recipientCount + " Recipients" : 'No Recipients Assigned'}
      <Button variant="outline-primary" className="mx-2" size="sm" as={Link} to={"/dashboard/recipients/" + campaignId}><i className="bi bi-pencil" /></Button>
    </>;
  };

  const handleOpenAssignMessage = (campaign, messageId) => {
    setSelectedMessage(messageId);
    setSelectedCampaignId(campaign._id); // Store the campaign ID
    setShowAssignModal(true);
  };

  const handleOpenAssignDesign = (campaign, designId) => {
    setSelectedDesign(designId)
    setSelectedCampaignId(campaign._id); // Store the campaign ID
    setShowAssignDesignModal(true);
  };

  const handleCloseAssignModal = () => {
    setShowAssignModal(false); // Hide the modal
    setSelectedMessage(null); // Reset the selected message
    setShowAssignDesignModal(false);
    setSelectedDesign(null);
  };

  const handleAssignMessage = async () => {
    try {
      const assignmentData = {
        userId: userId,
        campaignId: selectedCampaignId,
        messageId: selectedMessage
      };

      await assignMessage(assignmentData);
      log('Message assigned successfully');
    } catch (error) {
      console.error('Error assigning message:', error);
    }
    setShowAssignModal(false);
    triggerCampaignReload(); // Reload campaigns after assignment
  };

  const handleAssignDesign = async () => {
    try {
      const assignmentData = {
        userId: userId,
        campaignId: selectedCampaignId,
        designId: selectedDesign
      };

      await assignDesign(assignmentData);
      log('Design assigned successfully');
      setShowAssignDesignModal(false);
    } catch (error) {
      console.error('Error assigning design:', error);
    }
    triggerCampaignReload(); // Reload campaigns after assignment
  };

  const handleCreateNewCampaign = () => {
    setShowQuickCampaignModal(false); // Ensure Quick Campaign modal is closed
    setShowModal(false); // Ensure standard modal is closed
    setEditingCampaign(null);
    setShowWizard(true); // Open Campaign Wizard
  };

  // Handle copying a campaign
const handleCopyCampaign = (campaign) => {
  setCampaignToCopy(campaign);
  setShowCopyModal(true);
};

const executeCopyCampaign = async (newCampaignName, makeChildCampaign) => {
  try {
    // Find assigned message and design for the campaign
    const assignedMessage = assignedMessages.find(msg => msg.campaignId === campaignToCopy._id);
    const assignedDesign = assignedDesigns.find(design => design.campaignId === campaignToCopy._id);
    
    // Create a new campaign object with copied data
    const campaignData = {
      campaignName: newCampaignName,
      userId: userId,
      customFields: campaignToCopy.customFields || [],
      campaignType: campaignToCopy.campaignType,
      campaignStyle: campaignToCopy.campaignStyle,
      statusV1: 'draft'
    };
    
    // If makeChildCampaign is true and the original campaign has a parent
    // OR the original campaign itself is a parent, use appropriate parentCampaign value
    if (makeChildCampaign) {
      // If the original is a child, use its parent
      if (campaignToCopy.parentCampaign) {
        campaignData.parentCampaign = campaignToCopy.parentCampaign;
      } 
      // If the original is a parent, use it as the parent
      else {
        campaignData.parentCampaign = campaignToCopy._id;
      }
    }
    
    // Step 1: Create the new campaign
    const response = await createCampaign(campaignData);
    const newCampaignId = response.data.savedCampaign._id;
    
    // Step 2: Assign message if it exists
    if (assignedMessage) {
      const messageAssignmentData = {
        userId: userId,
        campaignId: newCampaignId,
        messageId: assignedMessage.messageId,
        assignedDate: new Date() // Add the required assignedDate field
      };
      
      await assignMessage(messageAssignmentData);
    }
    
    // Step 3: Assign design if it exists
    if (assignedDesign) {
      const designAssignmentData = {
        userId: userId,
        campaignId: newCampaignId,
        designId: assignedDesign.designId
      };
      
      await assignDesign(designAssignmentData);
    }
    
    // Reload campaigns after successful copy
    triggerCampaignReload();
  } catch (error) {
    console.error('Error copying campaign:', error);
    alert('Failed to copy campaign. Please try again.');
  }
};
  
  const groupedCampaigns = groupCampaignsByParent(campaigns);

// Filter grouped campaigns based on search
const filteredGroupedCampaigns = groupedCampaigns.filter(({ parent, children }) => {
  const matchesParent = parent?.campaignName.toLowerCase().includes(searchQuery.toLowerCase());
  const matchesChild = children.some(child => child.campaignName.toLowerCase().includes(searchQuery.toLowerCase()));
  return matchesParent || matchesChild;
});

// // Apply pagination to filtered results
// const paginatedParents = filteredGroupedCampaigns.slice(
//   (currentPage - 1) * itemsPerPage,
//   currentPage * itemsPerPage
// );

const calculatePaginatedParents = (groupedCampaigns, currentPage, itemsPerPage, expandedParents) => {
  const result = [];
  let rowCount = 0;

  for (const group of groupedCampaigns) {
    // Always include the parent campaign
    if (rowCount >= (currentPage - 1) * itemsPerPage && rowCount < currentPage * itemsPerPage) {
      result.push(group);
    }

    // Increment row count for the parent
    rowCount++;

    // If parent is expanded, include its children
    if (expandedParents.includes(group.parent?._id)) {
      for (const child of group.children) {
        if (rowCount >= (currentPage - 1) * itemsPerPage && rowCount < currentPage * itemsPerPage) {
          result.push({ parent: null, children: [child] }); // Treat child as its own renderable group
        }
        rowCount++;
      }
    }

    // Stop adding once the current page's limit is reached
    if (rowCount >= currentPage * itemsPerPage) break;
  }

  return result;
};

const sortCampaigns = (data, field, order) => {
  if (!field) return data; // No sorting if no field is specified

  return [...data].sort((a, b) => {
    const aValue = a.parent?.[field] || ''; // Sort by parent field
    const bValue = b.parent?.[field] || '';

    // Handle numeric and string sorting
    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return order === 'asc' ? aValue - bValue : bValue - aValue;
    } else {
      return order === 'asc'
        ? aValue.localeCompare(bValue, undefined, { numeric: true, sensitivity: 'base' })
        : bValue.localeCompare(aValue, undefined, { numeric: true, sensitivity: 'base' });
    }
  });
};

const sortedCampaigns = sortCampaigns(filteredGroupedCampaigns, sortField, sortOrder);
const paginatedParents = calculatePaginatedParents(sortedCampaigns, currentPage, itemsPerPage, expandedParents);




const handleSort = (field) => {
  if (sortField === field) {
    // Toggle sort order if the same field is clicked
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  } else {
    // Set a new sort field and default to ascending
    setSortField(field);
    setSortOrder('asc');
  }
};



// Calculate total pages for pagination
const totalPages = Math.ceil(filteredGroupedCampaigns.length / itemsPerPage);

  
  
  // const groupedCampaigns = groupCampaignsByParent(displayedCampaigns);
  
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber); // Update the current page
  };

  
  return (
    <Container fluid>
      <Row>
      <Col>
          <h1>Campaigns</h1>
        </Col>
        <Col>
          <div className="float-end">
            {/* Add Quick Campaign Button */}
           {/* Add Quick Campaign Button */}
            {hasCustomFieldsForCreation() && (
              <Button variant="info" className="me-2" onClick={handleCreateQuickCampaign}>
                Create Quick Campaign
              </Button>
            )}

            {/* Existing Create Campaign Button */}
            <Button onClick={handleCreateNewCampaign}>Create New Campaign</Button>
            <CampaignWizard
            show={showWizard}
            onHide={() => setShowWizard(false)}
            userId={userId}
            editingCampaign={editingCampaign}
          />
          </div>
        </Col>
      </Row>
      <Row>
        <p className='text-muted'> Campaigns bring messages, designs, and recipients together to carry your handwritten messages around the world.</p>
      </Row>
      <Row className="mb-3">
      <Col md={4}>
        <Form.Control
          type="text"
          placeholder="Search campaigns..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)} // Update search query
        />
      </Col>
    </Row>

      <Row className='mt-3'>
        {/* Table to display campaigns */}
        {/* Table to display campaigns */}
      {/* Table to display campaigns */}
      {(campaigns.length === 0)
        ? <><div className='d-flex justify-content-center'>Looks like you don't have any campaigns - let's make our first one!</div></>
        :
        <Table striped bordered hover className="table table-striped table-bordered">
          <thead>
            <tr>
            <th onClick={() => handleSort('campaignName')}>
              Campaign Name 
              <span
                style={{
                  color: sortField === 'campaignName' ? 'black' : 'black',
                  fontSize: '1.2em', // Adjust size
                  marginLeft: '8px', // Add spacing
                  fontWeight: sortField === 'campaignName' ? 'bold' : 'normal', // Emphasize active icon
                }}
              >
                {sortField === 'campaignName' 
                  ? (sortOrder === 'asc' ? '▲' : '▼') 
                  : '⬍'}
              </span>
              </th>
              <th onClick={() => handleSort('statusV1')}>
                Status 
                <span
                  style={{
                    color: sortField === 'statusV1' ? 'black' : 'black',
                    fontSize: '1.2em', // Adjust size
                    marginLeft: '8px',
                    fontWeight: sortField === 'statusV1' ? 'bold' : 'normal',
                  }}
                >
                  {sortField === 'statusV1' 
                    ? (sortOrder === 'asc' ? '▲' : '▼') 
                    : '⬍'}
                </span>
              </th>



              <th>Message</th>
              <th>Designs</th>
              <th>Recipients</th>
              <th onClick={() => handleSort('createdAt')}>
                Created Date 
                <span
                  style={{
                    color: sortField === 'createdAt' ? 'black' : 'black',
                    fontSize: '1.2em', // Adjust size
                    marginLeft: '8px',
                    fontWeight: sortField === 'createdAt' ? 'bold' : 'normal',
                  }}
                >
                  {sortField === 'createdAt' 
                    ? (sortOrder === 'asc' ? '▲' : '▼') 
                    : '⬍'}
                </span>
              </th>
              <th>Actions</th>
              <th>Next Step</th>
            </tr>
          </thead>
          <tbody>
  {paginatedParents.map(({ parent, children }) => (
    <React.Fragment key={parent?._id || Math.random()}>
      {/* Render the parent campaign */}
      {parent && (
        <tr>
          <td>
            {/* Tooltip and Parent Campaign Name */}
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={(props) => renderTooltip(props, parent)}
            >
              <span style={{ cursor: 'pointer' }}>
                {children.length > 0 && (
                  <span onClick={() => toggleExpandParent(parent._id)}>
                    {expandedParents.includes(parent._id) ? '▼' : '►'}
                  </span>
                )}
                {' '}
                <strong>{parent.campaignName}</strong>
              </span>
            </OverlayTrigger>
          </td>
          <td><CampaignStatusCell campaign={parent} /></td>
          <td>{createMessageCell(parent, assignedMessages.find(msg => msg.campaignId === parent._id)?.messageId)}</td>
          <td>{createDesignCell(parent, assignedDesigns.find(design => design.campaignId === parent._id)?.designId)}</td>
          <td>{createRecipientCell(parent._id)}</td>
          <td>{new Date(parent.createdAt).toLocaleDateString()}</td>
          <td>
          <div className="d-flex">
            {/* Delete Button */}
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-delete-${parent._id}`}>
                  {parent.statusV1 === 'draft'
                    ? 'Click to delete this draft campaign'
                    : 'Unable to delete a live or in-review campaign'}
                </Tooltip>
              }
            >
              <Button
                variant={parent.statusV1 === 'draft' ? 'outline-danger' : 'outline-secondary'}
                size="sm"
                onClick={parent.statusV1 === 'draft' ? () => handleDeleteCampaign(parent._id) : null}
                disabled={parent.statusV1 !== 'draft'}
                className="me-1"
              >
                <i className="bi bi-trash"></i>
              </Button>
            </OverlayTrigger>
            
            {/* Copy Button */}
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-copy-${parent._id}`}>
                  Copy this campaign
                </Tooltip>
              }
            >
              <Button
                variant="outline-primary"
                size="sm"
                onClick={() => handleCopyCampaign(parent)}
              >
                <i className="bi bi-files"></i>
              </Button>
            </OverlayTrigger>
          </div>
        </td>

          <td>
            <CampaignNextStep
              campaign={parent}
              handleSubmitForReview={handleSubmitForReview}
              assignedDesign={assignedDesigns.find(design => design.campaignId === parent._id)}
              assignedMessage={assignedMessages.find(msg => msg.campaignId === parent._id)}
              campaignData={recipientCount.find(rc => rc.campaignId === parent._id)}
              handleCancelReview={handleCancelReview}
              handleCloseCampaign={handleCloseCampaign}
            />
          </td>
        </tr>
      )}
      {/* Render the child campaigns if parent is expanded */}
      {expandedParents.includes(parent?._id) && children.map((child) => (
        <tr key={child._id} style={{ backgroundColor: '#f9f9f9' }}>
          <td style={{ paddingLeft: '30px' }}>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={(props) => renderTooltip(props, child)}
            >
              <span>{`→ ${child.campaignName}`}</span>
            </OverlayTrigger>
          </td>
          <td><CampaignStatusCell campaign={child} /></td>
          <td>{createMessageCell(child, assignedMessages.find(msg => msg.campaignId === child._id)?.messageId)}</td>
          <td>{createDesignCell(child, assignedDesigns.find(design => design.campaignId === child._id)?.designId)}</td>
          <td>{createRecipientCell(child._id)}</td>
          <td>{new Date(child.createdAt).toLocaleDateString()}</td>
          <td>
            <div className="d-flex">
              {/* Delete Button */}
              <Button 
                variant="outline-danger" 
                size="sm" 
                onClick={() => handleDeleteCampaign(child._id)}
                className="me-1"
              >
                <i className="bi bi-trash"></i>
              </Button>
              
              {/* Copy Button */}
              <Button
                variant="outline-primary"
                size="sm"
                onClick={() => handleCopyCampaign(child)}
              >
                <i className="bi bi-files"></i>
              </Button>
            </div>
          </td>
          <td>
            <CampaignNextStep
              campaign={child}
              handleSubmitForReview={handleSubmitForReview}
              assignedDesign={assignedDesigns.find(design => design.campaignId === child._id)}
              assignedMessage={assignedMessages.find(msg => msg.campaignId === child._id)}
              campaignData={recipientCount.find(rc => rc.campaignId === child._id)}
              handleCancelReview={handleCancelReview}
              handleCloseCampaign={handleCloseCampaign}
            />
          </td>
        </tr>
      ))}
    </React.Fragment>
  ))}
</tbody>


        </Table>
        
      }
      <Pagination className="justify-content-center mt-3">
        {[...Array(totalPages)].map((_, index) => (
          <Pagination.Item
            key={index + 1}
            active={index + 1 === currentPage}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
      </Pagination>

        <CreateCampaignModal showModal={showModal} setShowModal={setShowModal} handleCloseModal={handleCloseModal} userCountry={userCountry} editingCampaign={editingCampaign} userId={userId} />

        <Modal show={showAssignModal} onHide={() => setShowAssignModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Assign Message</Modal.Title>
          </Modal.Header>
          {
            (userMessages.length === 0)
              ?
              <>
                <Modal.Body>
                  <div className="d-flex justify-content-center">
                    Looks like you haven't created a message yet.
                  </div>
                  <div className='d-flex justify-content-center'>
                    <Link to="/dashboard/messages/create"><Button>Create New Message</Button></Link>
                  </div>
                </Modal.Body>
              </>
              :
              <>
                <Modal.Body>
                  <Form>
                  <Form.Group controlId="messageDropdown">
                    <Form.Label>Select a Message</Form.Label>
                    <Select
                      options={messageOptions}
                      onChange={(selectedOption) => setSelectedMessage(selectedOption ? selectedOption.value : '')}
                      isSearchable
                      isClearable
                      placeholder="Search and select a message..."
                    />
                  </Form.Group>
                    <Form.Group controlId="messageContent">
                      <Form.Label>Message Content</Form.Label>
                      <div className="read-only-textarea">
                        {selectedMessage ? parse(userMessages.find(msg => msg._id === selectedMessage).messageContent) : ''}
                      </div>
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseAssignModal}>Close</Button>
                  <Button variant="primary" disabled={!selectedMessage} onClick={handleAssignMessage}>Assign Message</Button>
                </Modal.Footer>
              </>
          }

        </Modal>
        {/* Create Quick Campaign Modal */}
      <CreateQuickCampaign
        show={showQuickCampaignModal}
        onHide={handleCloseQuickCampaignModal}
        userId={userId}
        editingCampaign={editingCampaign}
      />


        <Modal show={showDesignAssignModal} onHide={() => setShowAssignDesignModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Assign Design</Modal.Title>
          </Modal.Header>
          {
            (userDesigns.length === 0)
              ?
              <>
                <Modal.Body>
                  <div className="d-flex justify-content-center">
                    Looks like you haven't created a design yet.
                  </div>
                  <div className='d-flex justify-content-center'>
                    <Link to="/dashboard/designs/"><Button>Create New Design</Button></Link>
                  </div>
                </Modal.Body>
              </>
              :
              <>
                <Modal.Body>
                  <Form>
                    <Form.Group controlId="designDropdown">
                      <Form.Label>Select a Design</Form.Label>
                      <Form.Control as="select" onChange={(e) => setSelectedDesign(e.target.value)} defaultValue={selectedDesign ? selectedDesign : ''}>
                        <option disabled value="">Please select your design to view</option>
                        {userDesigns && userDesigns.length > 0 && userDesigns.map((dsg) => (
                          <option key={dsg._id} value={dsg._id}>{dsg.designName}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseAssignModal}>Close</Button>
                  <Button variant="primary" disabled={!selectedDesign} onClick={handleAssignDesign}>Assign Design</Button>
                </Modal.Footer>
              </>
          }
        </Modal>
      </Row>
      {/* Copy Campaign Modal */}
      <CopyCampaignModal
        show={showCopyModal}
        onHide={() => setShowCopyModal(false)}
        onCopyCampaign={executeCopyCampaign}
        campaign={campaignToCopy}
      />
    </Container>
  );
};

export default CampaignsPage;
