import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import "../styles/loading.css";

const loadingMessages = [
  "Reviewing your letters...",
  "Filtering your campaigns...",
  "Stuffing your envelopes...",
  "Licking your stamps...",
  "Sealing the deal...",
  "Sorting the mail...",
  "Checking the ink levels...",
  "Finding the best postage rates...",
  "Optimising your response rate...",
];

const LoadingComponent = () => {
  const [messageIndex, setMessageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setMessageIndex((prevIndex) => (prevIndex + 1) % loadingMessages.length);
    }, 2500); // Changes message every 2.5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="text-center py-5">
      {/* Animated Spinner */}
      <Spinner animation="grow" variant="primary" size="lg" />

      {/* Rotating Loading Message */}
      <h4 className="mt-3">
        {loadingMessages[messageIndex]} <span className="dots">...</span>
      </h4>

      {/* Progress Bar */}
      <div className="progress mt-3" style={{ height: "6px", width: "50%", margin: "auto" }}>
        <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: "75%" }}></div>
      </div>
    </div>
  );
};

export default LoadingComponent;
