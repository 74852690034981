import React, { useEffect, useState } from 'react';
import { fetchReportingCustomFields, fetchFilteredCampaigns } from '../services/api';
import { Container, Row, Col, Form, Table, Button, Pagination } from 'react-bootstrap';
import '../styles/reporting.css';

const CustomFieldReporting = () => {
  const [customFields, setCustomFields] = useState([]);
  const [selectedField, setSelectedField] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [campaigns, setCampaigns] = useState([]);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 15;
  
  useEffect(() => {
    const loadCustomFields = async () => {
      try {
        const response = await fetchReportingCustomFields();
        const formattedFields = response.data.flatMap(field => 
          field.campaigns.map(campaign => ({
            name: field.name || 'Unnamed Field', 
            values: campaign.values
          }))
        );
        setCustomFields(formattedFields);
      } catch (error) {
        console.error('Error fetching custom fields:', error);
      }
    };
    loadCustomFields();
  }, []);

  const handleFilterApply = async () => {
    if (!selectedField || selectedValue === 'All') {
      setSelectedValue('All');
      try {
        const response = await fetchFilteredCampaigns({});
        setCampaigns(response.data.sort((a, b) => b.responseRate - a.responseRate));
        setFiltersApplied(true);
      } catch (error) {
        console.error('Error fetching campaigns:', error);
      }
      return;
    }
    try {
      const response = await fetchFilteredCampaigns({ customFieldName: selectedField, customFieldValue: selectedValue });
      setCampaigns(response.data.sort((a, b) => b.responseRate - a.responseRate));
      setFiltersApplied(true);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    }
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = campaigns.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(campaigns.length / rowsPerPage);

  return (
    <Container className="py-4">
      <h1 className="text-center">Reporting by Custom Campaign Fields</h1>
      <h5 className="text-center mb-4">This page enables you to report at a campaign level by custom fields</h5>
      
      <Row className="mb-4">
        <Col md={6}>
          <Form.Label>Select Custom Field</Form.Label>
          <Form.Control as="select" value={selectedField} onChange={(e) => setSelectedField(e.target.value)}>
            <option value="">-- Select Field --</option>
            {[...new Set(customFields.map(field => field.name))].map((name, index) => <option key={index} value={name}>{name}</option>)}
          </Form.Control>
        </Col>
        <Col md={6}>
          <Form.Label>Select Value</Form.Label>
          <Form.Control as="select" value={selectedValue} onChange={(e) => setSelectedValue(e.target.value)} disabled={!selectedField}>
            <option value="All">All</option>
            {selectedField && [...new Set(customFields.filter(field => field.name === selectedField).flatMap(field => field.values))].map((value, index) => <option key={index} value={value}>{value}</option>)}
          </Form.Control>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col className="text-center">
          <Button onClick={handleFilterApply}>Apply Filter</Button>
        </Col>
      </Row>
      
      {filtersApplied && campaigns.length === 0 && (
        <p className="text-center">No campaigns found matching the selected filters.</p>
      )}
      
      {campaigns.length > 0 && (
        <>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Campaign Name</th>
                <th>Created At</th>
                <th>Recipients</th>
                <th>Response Rate (%)</th>
              </tr>
            </thead>
            <tbody>
              {currentRows.map(campaign => (
                <tr key={campaign._id}>
                  <td>{campaign.campaignName || 'Unnamed Campaign'}</td>
                  <td>{new Date(campaign.createdAt).toLocaleDateString()}</td>
                  <td>{campaign.totalRecipients}</td>
                  <td>{campaign.responseRate ? (campaign.responseRate * 100).toFixed(2) + '%' : '0.00%'}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination className="justify-content-center">
            {[...Array(totalPages).keys()].map(page => (
              <Pagination.Item
                key={page + 1}
                active={currentPage === page + 1}
                onClick={() => setCurrentPage(page + 1)}
              >
                {page + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </>
      )}
    </Container>
  );
};

export default CustomFieldReporting;
