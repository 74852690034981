import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet, useParams } from 'react-router-dom';
import HomePage from './components/HomePage';
import Dashboard from './components/Dashboard';
import SignInPage from './components/SignInPage';
import SignUpPage from './components/SignUpPage';
import RequireAuth from './components/RequireAuth';
import Layout from './components/Layout';
import CampaignsPage from './components/CampaignsPage'; // Import the CampaignsPage component
import AccountPage from './components/AccountPage'; // Import the AccountPage component
import MessagesPage from './components/MessagesPage';
import DesignsPage from './components/DesignsPage';
import RecipientsPage from './components/RecipientsPage';
import CreateEditMessage from './components/CreateEditMessage';
import CreateFromTemplate from './components/CreateFromTemplate';
import NewRecipient from './components/NewRecipient';
import ApiPage from './components/ApiPage';
import Billing from './components/Billing';
import NotFound from './components/NotFound';
import SignaturesPage from './components/SignaturePage';
import ProductsPage from './components/ProductPage';
import PricingPage from './components/PricingPage';
import TrackingPage from './components/TrackingPage';
import TrackingAdminPage from './components/TrackingAdmin';
import DocumenationPage from './components/DocumentationPage';
import CreateSVGMessage from './components/CreateSVGMessage';
import PendingApprovalPage from './components/PendingApprovalPage';
import CustomFieldsPage from './components/CustomFields';
import ImportTemplatesPage from './components/ImportTemplates';
import WebhooksPage from './components/Webhooks';
import CustomProductPage from './components/CustomProductPage';
import PreviewMessage from './components/PreviewMessage';
import MessagePreview from './components/MessagePreview';
import TestMessage from './components/TestMessage';
import A4Editor from './components/A4Editor';
import Reporting from './components/Reporting';
import CustomFieldReporting from './components/CustomFieldReporting';
import DataExport from './components/DataExport';

const AppRouter = () => (
  <Router>
    <Routes>
      <Route path="/" element={<RequireAuth><Navigate to="/dashboard" /></RequireAuth>} />
      <Route path="/signin" element={<SignInPage />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route path="/pending-approval" element={<PendingApprovalPage />} />
     
      {/* Nested routes for dashboard within RequireAuth */}
      <Route path="/dashboard" element={<RequireAuth><Layout /></RequireAuth>}>
        <Route index element={<Dashboard />} />
        <Route path="campaigns" element={<CampaignsPage />} />
        <Route path="account" element={<AccountPage />} />
        <Route path="messages" element={<MessagesPage />} />
        <Route path="messages/create" element={<CreateEditMessage />} />
        <Route path="messages/edit/:messageId" element={<CreateEditMessage />} />
        <Route path="designs" element={<DesignsPage />} />
        <Route path="designs/create/template" element={<CreateFromTemplate />} />
        <Route path="recipients/:campaignId" element={<RecipientsPage />} />
        <Route path="recipients/:campaignId/new" element={<NewRecipient />} />
        <Route path="recipients/preview-message/:recipientId" element={<PreviewMessage />} />
        <Route path="documentation" element={<DocumenationPage/>}/>
        <Route path="account/signatures" element={<SignaturesPage/>} />
        <Route path="account/api" element={<ApiPage />} />
        <Route path="settings/custom-fields" element={<CustomFieldsPage/> } />
        <Route path="billing" element={<Billing />} />
       
        <Route path="recipients/preview/:campaignId/:recipientId" element={<MessagePreview />} />

        <Route path="pricing" element={<PricingPage />} />
        <Route path="settings/import-templates" element={<ImportTemplatesPage/>} />
        <Route path="messages/a4editor/:messageId" element={<A4Editor />} />

        <Route path="testmessage" element={<TestMessage />} />
        <Route path="reporting" element={<Reporting/>} />
        <Route path="reporting/dataexport" element={<Reporting/>} />

        <Route path="reporting/customfields" element={<CustomFieldReporting/>} />
        {/* <Route path="settings/webhooks"  element={<WebhooksPage/> } />
        <Route path="testmessage/:campaignId/:recipientId" element={<TestMessage />} />
        <Route path="products/custom" element={<CustomProductPage />} />
        <Route path="tracking" element={<TrackingPage /> }/>
        <Route path="tracking/admin" element={<TrackingAdminPage/> }/>
         <Route path="products" element={<ProductsPage />} />
        <Route path="svg" element={<CreateSVGMessage/> }/> */}
        {/* Add more nested routes here */}
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  </Router>
);

export default AppRouter;

