import React from 'react';
import { useState } from 'react';
import { Link, useNavigate, Outlet } from 'react-router-dom';
import { useUserContext } from '../services/UserProvider';
import signOutUser from '../services/signout'; // Adjust the path according to your structure
import { Container, Row, Col, Nav, Button, Navbar, Offcanvas } from 'react-bootstrap';
import { FaRegEnvelope, FaPalette, FaUser, FaBroadcastTower, FaChartBar, FaUpload, FaRegFileAlt, FaChevronDown, FaChevronRight, FaMoneyBill, FaCog } from 'react-icons/fa';
import '../styles/Sidebar.css';
import '../styles/Nav.css';
import LogoImage from '../assets/Logo-LargeSV.png'; // Adjust the path as necessary




const Layout = ({ children }) => {
  const { setUser } = useUserContext();
  let navigate = useNavigate();
  const [showAccountSubmenu, setShowAccountSubmenu] = useState(false);
  const [showSetupMenu, setShowSetupMenu] = useState(false);
  const [expanded, setExpanded] = useState(false);



  const handleSignOut = () => {
    signOutUser(setUser, navigate);
  };

  return (
    <Container fluid>
      <Row>
        <Navbar expand="lg" className="custom-navbar" style={{ boxShadow: '0 0px 0px rgba(0,0,0,0.1)' }}>
          <Container fluid>
            <Nav className="mx-auto">
              <Navbar.Brand href="/dashboard">
                <img
                  src={LogoImage} // Change this to the path of your logo
                  width="200" // Set the width of your logo
                  height="50" // Set the height of your logo
                  className="d-inline-block align-top"
                  alt="Logo"
                  style={{ display: 'block', maxWidth: '100%', height: 'auto' }} // Ensures the image is centered and responsive
                />
              </Navbar.Brand>
            </Nav>
          </Container>
        </Navbar>
        <Row>
          <Col md={2} className="sidebar bg-light">
            <Navbar bg="light" className="side-navbar" expand="md" onToggle={setExpanded} expanded={expanded}>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Offcanvas
                id="responsive-navbar-nav"
                aria-labelledby="responsive-navbar-nav"
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id="offcanvasNavbarLabel">Sidebar</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="flex-column min-vh-100">
                    <Nav.Link as={Link} to="/dashboard/campaigns">
                      <FaBroadcastTower /> Campaigns <span className="arrow-icon" />
                    </Nav.Link>
                    <Nav.Link as={Link} to="/dashboard/messages/">
                      <FaRegEnvelope /> Messages <span className="arrow-icon" />
                    </Nav.Link>
                    <Nav.Link as={Link} to="/dashboard/designs/">
                      <FaPalette /> Designs <span className="arrow-icon" />
                    </Nav.Link>
                    <Nav.Link as={Link} to="/dashboard/pricing/">
                      <FaMoneyBill /> Pricing <span className="arrow-icon" />
                    </Nav.Link>
                    <Nav.Link as={Link} to="/dashboard/reporting/">
                      <FaChartBar /> Reporting <span className="arrow-icon" />
                    </Nav.Link>
                    <Nav.Link onClick={() => setShowAccountSubmenu(!showAccountSubmenu)}>
                      <FaUser /> <div>Account <span className="arrow-icon"></span>{showAccountSubmenu ? <FaChevronDown /> : <FaChevronRight />}</div>
                    </Nav.Link>
                    {showAccountSubmenu && (
                      <div className="submenu">
                        <Nav.Link as={Link} to="/dashboard/account">Profile</Nav.Link>
                        <Nav.Link as={Link} to="/dashboard/account/signatures">Signature Pad</Nav.Link>
                        <Nav.Link as={Link} to="/dashboard/account/api">API</Nav.Link>
                        <Nav.Link as={Link} to="/dashboard/documentation">API Docs</Nav.Link>
                        <Nav.Link as={Link} to="/dashboard/billing">Billing</Nav.Link>
                      </div>
                    )}
                     <Nav.Link onClick={() => setShowSetupMenu(!showSetupMenu)}>
                      <FaCog/> <div>Settings <span className="arrow-icon"></span>{showSetupMenu ? <FaChevronDown /> : <FaChevronRight />}</div>
                    </Nav.Link>
                    {showSetupMenu && (
                      <div className="submenu">
                        <Nav.Link as={Link} to="/dashboard/settings/custom-fields">Custom Fields</Nav.Link>
                        <Nav.Link as={Link} to="/dashboard/settings/import-templates">Import Templates</Nav.Link>
                       
                      </div>
                    )}
                                            <Button variant="primary" style={{ width: 100 }} onClick={handleSignOut}>Sign Out</Button>

                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Navbar>
          </Col>
          <Col md={10} className="main-content mt-5"> {/* Main content */}
            <Outlet />
          </Col>
        </Row>
      </Row>
    </Container>

  );
};

export default Layout;
