import React, { useEffect, useState } from 'react';
import { 
  fetchOverallMetrics, 
  fetchFilteredCampaigns,
  fetchCampaignPerformance, 
  fetchAvailableFields, 
  fetchCampaignCustomFields 
} from '../services/api';

import { Button, Table, Row, Col, Card, Container, Form, ButtonGroup, Pagination, Spinner } from 'react-bootstrap';
import '../styles/reporting.css';
import LoadingComponent from './LoadingComponent';

const Reporting = () => {
  const [metrics, setMetrics] = useState(null);
  const [campaignPerformance, setCampaignPerformance] = useState([]);
  const [bestCampaign, setBestCampaign] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Filters state
  const [selectedUniqueIdentifier, setSelectedUniqueIdentifier] = useState('');
  const [selectedCustomField, setSelectedCustomField] = useState('');
  const [selectedCustomValue, setSelectedCustomValue] = useState('');
  const [dateFilter, setDateFilter] = useState('all'); 
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  // Available filter options
  const [availableFields, setAvailableFields] = useState([]);
  const [customFieldOptions, setCustomFieldOptions] = useState([]);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = campaignPerformance.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(campaignPerformance.length / rowsPerPage);

  //Standard identifiers for filtering from our Recipient schema.
  const standardIdentifiers = [
    'title', 'firstName', 'lastName', 'fullName', 
    'companyName', 'houseName', 'addressLine1', 
    'addressLine2', 'addressLine3', 'postcode', 
    'cityCounty'
  ];


  // ✅ Load filters (Unique Identifiers, Custom Fields) on page load
  useEffect(() => {
    const loadFilters = async () => {
      try {
        setLoading(true);
        
        const fieldsResponse = await fetchAvailableFields();
        setAvailableFields(fieldsResponse.data.customVariables || []);

        let storedIdentifier = localStorage.getItem('selectedUniqueIdentifier');

        // If no stored identifier or stored identifier doesn't follow the new format
        if (!storedIdentifier || !storedIdentifier.includes(':')) {
          // Default to first standard identifier
          if (standardIdentifiers.length > 0) {
            storedIdentifier = `standard:${standardIdentifiers[0]}`;
          } 
          // If no standard identifiers, try using the first custom identifier
          else if (fieldsResponse.data.customVariables.length > 0) {
            storedIdentifier = `custom:${fieldsResponse.data.customVariables[0]}`;
          }
          localStorage.setItem('selectedUniqueIdentifier', storedIdentifier);
        }

        setSelectedUniqueIdentifier(storedIdentifier);

        const customFieldsResponse = await fetchCampaignCustomFields();
        if (customFieldsResponse.data?.customFields) {
          const formattedFields = Object.keys(customFieldsResponse.data.customFields).map((field) => ({
            label: field,
            values: customFieldsResponse.data.customFields[field]
          }));
          setCustomFieldOptions(formattedFields);
        }

        setLoading(false);
      } catch (err) {
        console.error('⚠️ Failed to load filter options:', err);
        setLoading(false);
      }
    };

    loadFilters();
  }, []);

  
  // ✅ Run API Call Once Unique Identifier is Set
  useEffect(() => {
    if (selectedUniqueIdentifier) {
      applyFilters();
    }
  }, [selectedUniqueIdentifier]);

  // ✅ Store Unique Identifier on Change
  const handleUniqueIdentifierChange = (value) => {
    setSelectedUniqueIdentifier(value);
    localStorage.setItem('selectedUniqueIdentifier', value);
  };

  // Function to export table data to CSV
  const exportToCSV = () => {
    // Only proceed if we have data to export
    if (campaignPerformance.length === 0) {
      alert('No data available to export');
      return;
    }

    // Define the column headers
    const headers = ['Campaign Name', 'Recipients', 'Response Rate (%)'];
    
    // Create CSV content from the campaign performance data
    let csvContent = headers.join(',') + '\n';
    
    // Add each row of data
    campaignPerformance.forEach(campaign => {
      const row = [
        // Wrap text fields in quotes to handle commas in text
        `"${campaign.campaignName || 'Unnamed Campaign'}"`,
        campaign.totalRecipients,
        campaign.responseRate.toFixed(2)
      ];
      csvContent += row.join(',') + '\n';
    });
    
    // Create a Blob with the CSV data
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    
    // Create a download link and trigger download
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `campaign_performance_${new Date().toISOString().split('T')[0]}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const applyFilters = async () => {
    if (!selectedUniqueIdentifier) return; // Ensure valid identifier
  
    setLoading(true);
    setCurrentPage(1); // Reset pagination to page 1 when filtering
  
    let updatedStartDate = null;
    let updatedEndDate = null;
  
    if (dateFilter === 'thisMonth') {
      updatedStartDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString();
      updatedEndDate = new Date().toISOString();
    } else if (dateFilter === 'lastMonth') {
      updatedStartDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1).toISOString();
      updatedEndDate = new Date(new Date().getFullYear(), new Date().getMonth(), 0).toISOString();
    } else if (dateFilter === 'custom') {
      updatedStartDate = startDate;
      updatedEndDate = endDate;
    }
  
    // Parse the selected identifier to determine if it's custom or standard
    const [type, identifier] = selectedUniqueIdentifier.split(':');
    
    const filtersToSend = {
      uniqueIdentifier: identifier, // Extract the actual identifier name without the prefix
      isCustom: type === 'custom', // Set isCustom based on the prefix
      customFieldName: selectedCustomField,
      customFieldValue: selectedCustomValue,
      startDate: updatedStartDate,
      endDate: updatedEndDate
    };
  
    console.log("📢 Sending Filters to API:", filtersToSend);
  
    try {
      // Remove the overall metrics call
      const campaignPerformanceResponse = await fetchFilteredCampaigns(filtersToSend);
      
      // Calculate metrics from campaign performance data
      const calculatedMetrics = {
        totalCampaigns: campaignPerformanceResponse.data.length,
        totalRecipients: campaignPerformanceResponse.data.reduce((sum, campaign) => sum + campaign.totalRecipients, 0),
        responseRate: campaignPerformanceResponse.data.length > 0 
        ? (campaignPerformanceResponse.data.reduce((sum, campaign) => 
            sum + Math.round(campaign.totalRecipients * (campaign.responseRate / 100)), 0) / 
            campaignPerformanceResponse.data.reduce((sum, campaign) => sum + campaign.totalRecipients, 0)) * 100
        : 0
      };
      console.log('Calculated Metrics:', calculatedMetrics);

  
      setMetrics(calculatedMetrics);
      setCampaignPerformance(campaignPerformanceResponse.data.sort((a, b) => b.responseRate - a.responseRate));
  
      setBestCampaign(campaignPerformanceResponse.data.length > 0 ? campaignPerformanceResponse.data[0] : null);
      setLoading(false);
    } catch (err) {
      console.error('⚠️ Failed to fetch metrics:', err);
      setError('Failed to load metrics');
      setLoading(false);
    }
  };

  return (
    <Container className="py-4">
<h1 className="text-center">
  Reporting 
  <span style={{fontSize: '.9rem', color: 'red'}}>(Alpha)</span>
</h1><p className="text-center">Snapshot of your campaign performance.</p>

      {loading && <LoadingComponent />}


      {/* Filters Section */}
      <Row className="justify-content-center mb-3">
        {/* Unique Identifier */}
        <Col md={4}>
          <Form.Select 
            onChange={(e) => handleUniqueIdentifierChange(e.target.value)} 
            value={selectedUniqueIdentifier}
          >
            <option value="">Select Unique Identifier</option>
            
            {/* Standard Recipient Identifiers Group */}
            <optgroup label="Standard Recipient Identifiers">
              {standardIdentifiers.map((field, index) => (
                <option key={`standard-${index}`} value={`standard:${field}`}>
                  {field}
                </option>
              ))}
            </optgroup>
            
            {/* Custom Recipient Identifiers Group */}
            <optgroup label="Custom Recipient Identifiers">
              {availableFields.map((field, index) => (
                <option key={`custom-${index}`} value={`custom:${field}`}>
                  {field}
                </option>
              ))}
            </optgroup>
          </Form.Select>
        </Col>

        {/* Custom Fields */}
        <Col md={4}>
          <Form.Select onChange={(e) => setSelectedCustomField(e.target.value)} value={selectedCustomField}>
            <option value="">Filter by Campaign Custom Field</option>
            {customFieldOptions.map((field, index) => (
              <option key={index} value={field.label}>{field.label}</option>
            ))}
          </Form.Select>
        </Col>

        {/* Custom Field Value */}
        <Col md={4}>
          <Form.Select onChange={(e) => setSelectedCustomValue(e.target.value)} value={selectedCustomValue} disabled={!selectedCustomField}>
            <option value="">Select Value</option>
            {selectedCustomField && customFieldOptions.find(f => f.label === selectedCustomField)?.values.map((value, index) => (
              <option key={index} value={value}>{value}</option>
            ))}
          </Form.Select>
        </Col>
      </Row>

      {/* Date Filters Section */}
      <Row className="justify-content-center mb-3">
        <Col md={6}>
          <ButtonGroup className="w-100">
            <Button 
              variant={dateFilter === 'all' ? 'primary' : 'outline-primary'} 
              onClick={() => setDateFilter('all')}
            >
              All Time
            </Button>
            <Button 
              variant={dateFilter === 'thisMonth' ? 'primary' : 'outline-primary'} 
              onClick={() => setDateFilter('thisMonth')}
            >
              This Month
            </Button>
            <Button 
              variant={dateFilter === 'lastMonth' ? 'primary' : 'outline-primary'} 
              onClick={() => setDateFilter('lastMonth')}
            >
              Last Month
            </Button>
            <Button 
              variant={dateFilter === 'custom' ? 'primary' : 'outline-primary'} 
              onClick={() => setDateFilter('custom')}
            >
              Custom Range
            </Button>
          </ButtonGroup>
        </Col>

        {/* Date Pickers */}
        <Col md={3}>
          <Form.Control 
            type="date" 
            value={startDate} 
            onChange={(e) => {
              setStartDate(e.target.value);
              setDateFilter('custom'); // Auto-select Custom Range
            }} 
            disabled={dateFilter !== 'custom'}
          />
        </Col>
        <Col md={3}>
          <Form.Control 
            type="date" 
            value={endDate} 
            onChange={(e) => {
              setEndDate(e.target.value);
              setDateFilter('custom'); // Auto-select Custom Range
            }} 
            disabled={dateFilter !== 'custom'}
          />
        </Col>
      </Row>


      {/* Filter Button */}
      <Row className="justify-content-center mb-4">
        <Col md={2}>
          <Button onClick={applyFilters} className="w-100">Filter</Button>
        </Col>
      </Row>


      {/* Summary Metrics */}
      <Row className="text-center mb-4">
        <Col><Card><Card.Body>Total Campaigns: {metrics?.totalCampaigns || 0}</Card.Body></Card></Col>
        <Col><Card><Card.Body>Total Letters Sent: {metrics?.totalRecipients || 0}</Card.Body></Card></Col>
        <Col><Card><Card.Body>Response Rate: {metrics?.responseRate?.toFixed(2) || '0.00'}%</Card.Body></Card></Col>
      </Row>

      {/* Campaign Performance Table with Export Button */}
      <div className="d-flex justify-content-end mb-2">
        <Button 
          variant="success" 
          size="sm" 
          onClick={exportToCSV}
          disabled={campaignPerformance.length === 0}
        >
          Export to CSV
        </Button>
      </div>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Campaign Name</th>
            <th># Recipients</th>
            <th>Response Rate</th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map((campaign) => (
            <tr key={campaign._id}>
              <td>{campaign.campaignName || 'Unnamed Campaign'}</td>
              <td>{campaign.totalRecipients}</td>
              <td>{campaign.responseRate.toFixed(2)}%</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Pagination */}
      <Pagination className="justify-content-center mt-3">
        <Pagination.Prev disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)} />
        {[...Array(totalPages).keys()].map(num => (
          <Pagination.Item key={num} active={num + 1 === currentPage} onClick={() => setCurrentPage(num + 1)}>
            {num + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next disabled={currentPage === totalPages} onClick={() => setCurrentPage(currentPage + 1)} />
      </Pagination>

    </Container>
  );
};

export default Reporting;