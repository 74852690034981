import { jwtDecode } from 'jwt-decode'; // Check if jwt-decode is the correct import
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Form, Pagination, ProgressBar, Row, Table, Toast, ToastContainer, ToastHeader, ButtonGroup } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { read, utils } from 'xlsx';
import { useUserContext } from '../services/UserProvider';
import { fetchImportTemplates, createBulkRecipients, deleteRecipient, fetchCampaigns, getAssignedMessages, getUserMessages, listRecipients, uploadRawCSV } from '../services/api'; // Replace with your actual API call
import { log } from '../services/logger';
import '../styles/Recipients.css';
import '../styles/index.css';
import RecipientInputForm from './RecipientInputForm';
import RecipientViewTable from './RecipientViewTable';
import { SiElementary } from 'react-icons/si';
import RecipientProgressBar from './RecipientProgressBar';

const requiredRecipientFields = ["firstName", "lastName", "addressLine1"];
//const defaultRecipientFields = ["title", "firstName", "lastName", "companyName", "addressLine1", "addressLine2", "addressLine3", "cityCounty", "postcode", "country", "requestedPostDate"];
const defaultRecipientFields = ["fullName", "firstName", "lastName", "companyName", "addressLine1", "addressLine2", "addressLine3", "cityCounty", "postcode"];
const recipientStatusFields = ["submitted", "status", "actions"];
const emptyData = { headers: [], data: [] };

// const findVariables = (messageContent) => {
//     const rxp = /{{([^}]+)}}/g;
//     let variables = []
//     let match;
//     while (match = rxp.exec(messageContent)) {
//         variables.push(match[1]);
//     }
//     return variables;
// }
// const findVariables = (messageContent) => {
//   const rxp = /{{([^}]+)}}/g;
//   const variables = new Set();
//   let match;

//   while ((match = rxp.exec(messageContent))) {
//       variables.add(match[1].trim());
//   }

//   // Convert the Set back to an array before returning
//   return Array.from(variables);
// };

const findVariables = (messageContent) => {
  // Regex to capture variables, allowing optional [[\r]] before and after the variable name
  const rxp = /{{\s*(?:\[\[\\*r\]\])*\s*([^}]+?)\s*(?:\[\[\\*r\]\])*\s*}}/g;
  const variables = new Set();
  let match;

  while ((match = rxp.exec(messageContent))) {
    // Extract the variable name and trim it to remove any remaining spaces
    const variable = match[1].trim();
    variables.add(variable);
  }

  // Convert the Set back to an array before returning
  return Array.from(variables);
};



const fieldsToDisplayNames = (field) => {
    const result = field.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
}

const normalizeStrings = (string) => {
    return string.toLowerCase().replace(/\s+|_/g, '');
}

const RecipientsPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [selectedCampaign, setSelectedCampaign] = useState({});
    const [campaignAssignedMessageVariables, setCampaignAssignedMessageVariables] = useState([]);
    //   const [selectedViewCampaign, setSelectedViewCampaign] = useState(params.campaignId);
    const [viewingRecipientData, setViewingRecipientData] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const { user } = useUserContext(); // Use the user context
    const [userId, setUserId] = useState(null);
    const [file, setFile] = useState(null);
    const [data, setData] = useState(emptyData);
    const [recipientIdForEdit, setRecipientIdForEdit] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [refreshRecipients, setRefreshRecipients] = useState(false);

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = data.data?.slice(indexOfFirstRow, indexOfLastRow) || [];
    const [dates, setDates] = React.useState(["2024-01-01", "2024-01-02"]); // Example dates

    //Import Templates
    const [importTemplates, setImportTemplates] = useState([]); // State to hold the import templates
    const [selectedTemplate, setSelectedTemplate] = useState(""); 

    // Calculate total pages
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(data.data.length / rowsPerPage); i++) {
        pageNumbers.push(i);
    }

    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const toggleManaulAdd = () => {
        setShowForm(show => !show)
        closePreview()
    }

    const handleEdit = (recipientId) => {
        setRecipientIdForEdit(recipientId);
        setShowForm(true);
        closePreview();
    };

    const submitRecipients = async (recipients) => {
        try {
            
            createBulkRecipients(recipients).then(response => {
                log("Success bulk");
                closePreview();
                setSuccessMessage("Your bulk recipient upload was successful.");
                setRefreshRecipients(true);
            }).catch(error => {
                console.error(error);
            });
            // Handle successful submission
        } catch (error) {
            // Handle errors
        }
    };


    const handleFileUpload = async () => {
      try {
          if (file) {
              await uploadRawCSV({ file, campaignId: selectedCampaign._id });
              setSuccessMessage("CSV file uploaded successfully");
          }
      } catch (error) {
          console.error("Error uploading CSV file:", error);
      }
    };

    const sanitizeValue = (value) => {
      if (!value) return '';
  
      return value
          .toString()
          .replace(/\r/g, '')   // Remove carriage return but keep new lines
          .replace(/\n{3,}/g, '\n\n') // Prevent excessive blank lines
          .trim();
  };
  
  
    const handleBulkSubmit = async () => {
      const missingRequiredFields = getRequiredFields().filter(required => getUnMatchedFields().includes(required));
      if (missingRequiredFields.length !== 0) {
          if (!window.confirm("Are you sure you want to upload when missing the following required fields?\n" + missingRequiredFields)) return;
      }
  
      const aWeekAhead = new Date();
      aWeekAhead.setDate(aWeekAhead.getDate() + 7);
  
      const isTemplateSelected = !!selectedTemplate;
      const templateMapping = data.templateMapping || {};
      
      console.log("🔍 Template Mapping:", templateMapping);
      console.log("🔍 CSV Headers:", data.headers);
      console.log("🔍 First row of CSV data:", data.data[0]); // Log the first row of data
      
  
      const formattedData = data.data.map(row => {
          const entry = {
              customVariables: []
          };
  
          if (isTemplateSelected) {
              data.headers.forEach((header, index) => {
                  const recipientField = templateMapping[header];
                  
                  if (recipientField) {
                      entry[recipientField] = row[index];
                  } else {
                      entry.customVariables.push({
                          name: header,
                          value: row[index] || ''
                      });
                  }
              });
          } else {
            data.headers.forEach((header, index) => {
              const cleanValue = sanitizeValue(row[index]);
          
              // ✅ First, check if the header is in the standard recipient fields
              const matchingField = defaultRecipientFields.find(defaultField =>
                  normalizeStrings(defaultField) === normalizeStrings(header)
              );
          
              if (matchingField) {
                  console.log(`✅ Standard Field Matched: "${header}" → Saving as "${matchingField}"`);
                  entry[matchingField] = cleanValue;
              } else {
                  console.log(`⚠️ No standard match for "${header}", adding to customVariables.`);
                  entry.customVariables.push({
                      name: header,
                      value: cleanValue || ''
                  });
              }
          });

          
          }
  
          entry.statusV1 = "new";
          entry.requestedPostDate = aWeekAhead.getTime();
          entry.submittedDate = Date.now();
          entry.campaignId = selectedCampaign._id;
          entry.userId = userId;
  
          return entry;
      });
  
      try {
          if (file) {
              await handleFileUpload(); // Upload the file first
          }
      } catch (fileUploadError) {
          console.error("File upload failed:", fileUploadError);
          setSuccessMessage("File upload failed. Recipients were not submitted.");
          return; // Stop further execution if the file upload fails
      }
  
      try {
          await submitRecipients(formattedData); // Then submit the recipient data
          setSuccessMessage("Recipients and CSV file uploaded successfully");
      } catch (recipientsError) {
          console.error("Recipient submission failed:", recipientsError);
          setSuccessMessage("Recipients submission failed. File upload was successful.");
      }
  };
  

      
      
      

    const getCustomVariablesProperty = (entry) => {
        const customVariableValue = [];
        getCustomVariables().map(variable => {
            customVariableValue.push({"name": variable, "value": entry[variable]});
        })
        return customVariableValue;
    }


    useEffect(() => {
        const decodedToken = jwtDecode(user); // Assuming JWT is stored in user.token
        const tokenUserId = decodedToken.userId;
        setUserId(tokenUserId);
        log("User found on recipients page: ", decodedToken.userId);
        const campaignId = params.campaignId;

        // Fetch campaigns
        fetchCampaigns(tokenUserId).then(response => {
            const selected = response.data.find(c => c._id === campaignId);
            // Directly set selectedViewCampaign here if needed
            setSelectedCampaign(selected)
        }).catch(error => {
            console.error('Error fetching campaigns:', error);
        });

        getAssignedMessages(tokenUserId)
            .then(resp => {
                const campaignMessageId = resp.data.find(assignedMessage => assignedMessage.campaignId === params.campaignId)?.messageId;
                if (campaignMessageId) {
                    getUserMessages(tokenUserId).then(resp => {
                        setCampaignAssignedMessageVariables(findVariables(resp.data.find(msg => msg._id === campaignMessageId)?.messageContent));
                    })
                }
            })

        fetchRecipients(campaignId);
    }, []); // Removed data and selectedViewCampaign from dependency array as they seem unnecessary for this effect

    const fetchRecipients = async (campaignId) => {
        log("Fetching recipients for campaign:", campaignId);
        try {
            const response = await listRecipients(campaignId);
            setViewingRecipientData(response.data);
            log("Updated Recipient Data:", response.data);
        } catch (error) {
            console.error('Error fetching recipients:', error);
        }
    };

    useEffect(() => {
        if (refreshRecipients && selectedCampaign) {
            fetchRecipients(selectedCampaign._id);
            setRefreshRecipients(false);
        }
    }, [refreshRecipients])

    const handleOnCloseRecipientInputForm = () => {
        handleOnClearRecipientInputForm();
        setShowForm(false);
    }

    const handleOnClearRecipientInputForm = () => {
        setRecipientIdForEdit(null);
    }


    const closePreview = () => {
        setData(emptyData);
    }

    const getCustomVariables = () => {
        return campaignAssignedMessageVariables
            .filter(v => !defaultRecipientFields.map(normalizeStrings).includes(normalizeStrings(v)));
    }

    const getVariableHeaderStyle = (field) => {
        if (campaignAssignedMessageVariables.find(f => normalizeStrings(f) === normalizeStrings(field))) {
            return { "backgroundColor": "skyblue" };
        } else {
            return {}
        };
    }

    const getExpectedPreviewFields = () => {
        return defaultRecipientFields.concat(getCustomVariables());
    }

    const getExpectedViewFields = () => {
        return getExpectedPreviewFields().concat(recipientStatusFields);
    }

    const getTableViewHeader = () => {
        return (
            <tr>
                {getExpectedViewFields().map(field =>
                    <th style={getVariableHeaderStyle(field)}>{fieldsToDisplayNames(field)}</th>)}
            </tr>
        )
    }

    const getNormalizedHeaders = () => {
        return data.headers.map(normalizeStrings);
    }

    const getTablePreviewIndexes = () => {
        return getExpectedPreviewFields()
            .map(normalizeStrings)
            .map(field => data.headers.findIndex(header => normalizeStrings(header) === field));
    }


    const getRequiredFields = () => {
        return requiredRecipientFields.concat(getCustomVariables());
    }

    // IMPORT TEMPLATES LOGIC //

    useEffect(() => {
        // Fetch import templates when the page loads
        fetchImportTemplates(userId).then(response => {
          setImportTemplates(response.data);
        }).catch(error => {
          console.error('Error fetching import templates:', error);
        });
      }, [userId]);
    
      const handleTemplateChange = (e) => {
        setSelectedTemplate(e.target.value);
      };

      const handlePreview = (e) => {
        e.preventDefault();
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const bstr = e.target.result;
            const workbook = read(bstr, { type: 'binary' });
      
            const wsname = workbook.SheetNames[0];
            const ws = workbook.Sheets[wsname];
      
            const dataWithHeaders = utils.sheet_to_json(ws, { header: 1, raw: true, defval: '' });
            if (dataWithHeaders.length > 0) {
              const headers = dataWithHeaders[0];  // CSV headers
              const data = dataWithHeaders.slice(1);  // CSV rows
      
              let templateMapping = {};
              if (selectedTemplate) {
                const selectedTemplateMapping = importTemplates.find(template => template._id === selectedTemplate);
                if (selectedTemplateMapping) {
                  // Build template mapping from the 'fieldMappings' array
                  selectedTemplateMapping.fieldMappings.forEach(mapping => {
                    templateMapping[mapping.csvHeader] = mapping.recipientField;
                  });
                }
              }
      
              setData({
                headers,
                data,
                templateMapping  // Include the mappings in the state
              });
            }
          };
          reader.readAsBinaryString(file);
        }
      };
    
      // Helper function to render headers with their mapped equivalents when a template is selected
      const renderMappedHeaders = () => {
        return (
          <tr>
            {data.headers.map((header, index) => (
              <th key={index}>
                {header}
                <br />
                {data.templateMapping[header] ? (
                  <span className="badge bg-success">
                    {data.templateMapping[header]}
                  </span>
                ) : (
                  <span className="badge bg-danger">Unmapped</span>
                )}
              </th>
            ))}
          </tr>
        );
      };
      
//     // Function to normalize strings (removes spaces and makes lowercase)
// const normalizeStrings = (string) => {
//     return string.toLowerCase().replace(/\s+|_/g, '');
//   };
  
const normalizeStrings = (string) => {
  return string
      .toLowerCase() // Convert to lowercase for uniformity
      .trim() // Remove leading/trailing spaces
      .normalize('NFD') // Normalize Unicode to decompose diacritical marks
      .replace(/[\u0300-\u036f]/g, '') // Remove diacritical marks
      .replace(/[^a-z0-9]/g, ''); // Remove all non-alphanumeric characters
};

  
  // Function to get unmatched headers when no template is selected
  const getUnMatchedHeadersWithoutTemplate = () => {
    return data.headers.filter(
      (header) => !getExpectedPreviewFields().map(normalizeStrings).includes(normalizeStrings(header))
    );
  };
  
  // Function to get unmatched headers when a template is selected
  const getUnMatchedHeadersWithTemplate = () => {
    return data.headers.filter((header) => !Object.keys(data.templateMapping).includes(header));
  };
  
  // Main function to decide which unmatched headers function to use based on whether a template is selected
  const getUnMatchedHeaders = () => {
    if (selectedTemplate) {
      // If a template is selected, use template mapping logic
      return getUnMatchedHeadersWithTemplate();
    } else {
      // If no template is selected, use the default fields + custom variables logic
      return getUnMatchedHeadersWithoutTemplate();
    }
  };
  
  // Function to get unmatched required recipient fields (this works in both scenarios)
  const getMissingRecipientFields = () => {
    const comparisonFields = selectedTemplate
      ? Object.values(data.templateMapping)
      : data.headers; // If no template, compare against CSV headers
    const normalizedComparisonFields = comparisonFields.map((f) => normalizeStrings(f));
    
    return defaultRecipientFields.filter(
      (field) => !normalizedComparisonFields.includes(field.toLowerCase())
    );
  };
  
  const getUnMatchedFields = () => {
    // If using a template, compare against mapped fields
    if (selectedTemplate) {
      return getExpectedPreviewFields().filter((field) => !Object.values(data.templateMapping).includes(field));
    }
  
    // If no template is selected, compare against normalized CSV headers
    return getExpectedPreviewFields().filter((field) => !getNormalizedHeaders().includes(normalizeStrings(field)));
  };
  
  const getTablePreviewHeader = () => {
    return (
      <tr>
        {data.headers.map((header, index) => (
          <th key={index}>{header}</th>
        ))}
      </tr>
    );
  };
  
  

//         // Default behavior: if no template is selected, use the existing logic for unmatched fields, headers, etc.
//   const getUnMatchedHeaders = () => {
//     return data.headers.filter((header) => !Object.keys(data.templateMapping).includes(header));
//   };

//   const getTablePreviewHeader = () => {
//     return (
//       <tr>
//         {data.headers.map((header, index) => (
//           <th key={index}>{header}</th>
//         ))}
//       </tr>
//     );
//   };

//   const getUnMatchedFields = () => {
//     return data.headers.filter((header) => !data.templateMapping[header]);
//   };
      


    return (
        <Container fluid={true}>
            <Row>
                <Col><h1>{viewingRecipientData?.length} Recipients for {selectedCampaign ? selectedCampaign.campaignName : "loading"}</h1></Col>
            </Row>
            <Row>
                <p className='text-muted'>All recipient data is owned by the end users and stored securely by Penned.  Penned will never sell or repurpose this information.</p>
            </Row>
            <Row>
                <Col>
                    {campaignAssignedMessageVariables.length === 0 && <Card className='warning my-3 clickable-card' onClick={() => navigate("/dashboard/campaigns")}><Card.Body><Card.Title><i className='bi bi-exclamation-circle' /> You have not assigned a message to the campaign.  We cannot validate you are providing all of the required variables.  Please assign a message first.</Card.Title></Card.Body></Card>}
                </Col>
            </Row>
            <Row className="mb-3">
                <RecipientProgressBar recipientData={viewingRecipientData} />
            </Row>
            <Row className="mb-3">
                <Col className="d-flex flex-row">
                    <Card className="clickable-card" style={{ width: "100%" }} onClick={() => toggleManaulAdd()}>
                        <Card.Body>
                            <Card.Title>
                                Manually add recipients
                            </Card.Title>
                            <div className="mb-2">
                                <p className="mt-2">
                                    Add a recipient manually to your campaign list.
                                </p>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                    <Card.Body>
              <Card.Title>Upload a CSV</Card.Title>
              
                <>
                  <Form.Group controlId="select-import-template" className="mb-3">
                    <Form.Label>Select Import Template (If You Have One)</Form.Label>
                    <Form.Control as="select" value={selectedTemplate} onChange={handleTemplateChange}>
                      <option value="">Select a template</option>
                      {importTemplates.map(template => (
                        <option key={template._id} value={template._id}>
                          {template.templateName}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form onSubmit={handlePreview}>
                    <Form.Group controlId="custom-file" className="mb-3">
                      <Form.Label>Upload CSV File</Form.Label>
                      <Form.Control type="file" onChange={handleFileChange} />
                    </Form.Group>
                    <Button variant="primary" type="submit" className={"mt-2 " + ((!file) ? "disabled" : "")}>
                      Preview File
                    </Button>
                  </Form>
                </>
              
            </Card.Body>
                    </Card>
                </Col>
            </Row>
            {successMessage && <ToastContainer position="top-end" className='p-3' style={{ zIndex: 1 }}><Toast onClose={() => setSuccessMessage("")} show={successMessage} delay={10000} autohide><ToastHeader><strong className='me-auto'>penned</strong></ToastHeader><Toast.Body>{successMessage}</Toast.Body></Toast></ToastContainer>}

            {showForm && <RecipientInputForm selectedCampaign={selectedCampaign} setRefreshRecipients={setRefreshRecipients} customVariables={getCustomVariables()} recipientIdForEdit={recipientIdForEdit} onClear={handleOnClearRecipientInputForm} onClose={handleOnCloseRecipientInputForm} />}

             {/* CSV Preview Table */}
      {data.headers.length !== 0 && data.data.length !== 0 && (
        <Card className="p-4">
              <Col style={{ textAlign: 'left' }}>
              <ButtonGroup aria-label="Actions">
              <Button variant="secondary"  onClick={() => closePreview()}>
                Close Preview
              </Button>
              <Button variant="primary"  type="submit" onClick={handleBulkSubmit}>
                Upload {data.data.length} Recipients
              </Button>
              </ButtonGroup>
            </Col>
          <Card.Body>
          <Row>
  {/* Left side: Unmatched CSV fields */}
  {getUnMatchedHeaders().length !== 0 && (
    <Col>
      <Card>
        <Card.Header>CSV contains the following unmatched fields <p><strong>Unmapped fields automatically are imported into your recipient metadata as custom variables.</strong></p></Card.Header>
        <Card.Body>
          <ul>
            {getUnMatchedHeaders().map((h) => (
              <li key={h}>{h}</li>
            ))}
          </ul>
        </Card.Body>
      </Card>
    </Col>
  )}

  {/* Right side: Missing required recipient fields */}
  {getMissingRecipientFields().length !== 0 && (
    <Col>
      <Card>
        <Card.Header>Recipients are missing the following fields</Card.Header>
        <Card.Body>
          <ul>
            {getMissingRecipientFields().map((f) => (
              <li key={f}>{f}</li>
            ))}
          </ul>
        </Card.Body>
      </Card>
    </Col>
  )}
   <p></p><p>Any columns found in your CSV matching your import template will be marked green as 'mapped'. They'll automatically be uploaded to our recipient database with the mapped fields. Any unmapped fields (red) will be uploaded to the recipient object under customVariables.</p>
   
</Row>

          <Row>
    <div style={{ maxHeight: '400px', overflowY: 'auto', overflowX: 'auto', whiteSpace: 'nowrap' }}>
          <Table striped bordered hover className="mt-4">
        <thead>
          {selectedTemplate ? renderMappedHeaders() : getTablePreviewHeader()}
        </thead>
        <tbody>
          {currentRows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {data.headers.map((header, colIndex) => (
                <td key={colIndex}>{row[colIndex]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  </Row>
          </Card.Body>
        </Card>
      )}
      {/* Pagination and buttons */}
      <Row>
            <Col>
            {pageNumbers.length > 1 && (
                <Pagination className="d-flex justify-content-center">
                  {pageNumbers.map((number) => (
                    <Pagination.Item
                      key={number}
                      active={number === currentPage}
                      onClick={() => paginate(number)}
                    >
                      {number}
                    </Pagination.Item>
                  ))}
                </Pagination>
              )}
            </Col>
          
          </Row>
            <RecipientViewTable viewingRecipientData={viewingRecipientData} customVariables={getCustomVariables()} getTableViewHeader={getTableViewHeader} setRefreshRecipients={setRefreshRecipients} handleEdit={handleEdit}/>
        </Container>
    );
};

export default RecipientsPage;