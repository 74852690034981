import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const CopyCampaignModal = ({ show, onHide, onCopyCampaign, campaign }) => {
  const [newCampaignName, setNewCampaignName] = useState('');
  const [makeChildCampaign, setMakeChildCampaign] = useState(false);
  const [validated, setValidated] = useState(false);
  
  // Set default name when campaign changes or modal opens
  useEffect(() => {
    if (campaign && campaign.campaignName) {
      setNewCampaignName(`Copy of ${campaign.campaignName}`);
      // Reset the checkbox when a new campaign is selected for copying
      setMakeChildCampaign(false);
    }
  }, [campaign, show]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    // Pass both the new name and whether to make it a child campaign
    onCopyCampaign(newCampaignName, makeChildCampaign);
    onHide();
  };

  // Determine if we need to show the checkbox
  // - Show checkbox if it's a child campaign (has a parentCampaign)
  // - Show checkbox if it's a parent campaign (can create a child of itself)
  const showCheckbox = campaign !== null;
  
  // Determine the appropriate checkbox label based on the campaign type
  const checkboxLabel = campaign?.parentCampaign 
    ? "Keep as a child of the same parent campaign" 
    : "Create as a child of the original campaign";

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Copy Campaign</Modal.Title>
      </Modal.Header>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Body>
              {/* Description/subtitle for the modal */}
          <p className="text-muted mb-4">
            This function allows you to clone Campaigns with the existing assigned message and design. 
            You are able to add the cloned campaign as an existing child or as a brand new (parent) campaign.
          </p>
          <Form.Group controlId="campaignName" className="mb-3">
            <Form.Label>New Campaign Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter campaign name"
              value={newCampaignName}
              onChange={(e) => setNewCampaignName(e.target.value)}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a campaign name.
            </Form.Control.Feedback>
          </Form.Group>
          
          {showCheckbox && (
            <Form.Group controlId="makeChildCampaign">
              <Form.Check 
                type="checkbox"
                label={checkboxLabel}
                checked={makeChildCampaign}
                onChange={(e) => setMakeChildCampaign(e.target.checked)}
              />
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Copy Campaign
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CopyCampaignModal;